<template>
  <v-row class="bbr-promo--details-form">
    <v-col cols="12" sm="12">
      <promo-image-upload
        v-if="!isLoading"
        :image="originalPromoImage"
        @change="promoImageChange"
      />
      <v-skeleton-loader v-if="isLoading" type="image" />
      <div
        class="v-messages theme--light error--text"
        v-if="form.$getError('image')"
      >
        {{ form.$getError('image').join(', ') }}
      </div>
    </v-col>

    <v-col cols="12" sm="6">
      <v-checkbox
        v-if="!isLoading"
        v-model="form.is_featured"
        :disabled="disabledInputs"
        label="Is Featured"
        color="primary"
      />

      <v-skeleton-loader v-else type="text" />
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-if="!isLoading"
        v-model="form.title"
        label="Promo Title"
        :disabled="disabledInputs"
        :error-messages="form.$getError('title')"
        outlined
        flat
      />

      <v-skeleton-loader v-else type="text" />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-if="!isLoading"
        v-model="form.description"
        class="pb-12"
        label="Promo Description"
        :disabled="disabledInputs"
        :error-messages="form.$getError('description')"
        auto-grow
        outlined
        flat
      />

      <v-skeleton-loader v-else type="text" />
    </v-col>

    <v-col cols="12">
      <!-- <v-select
        label="Link To"
        :error-messages="form.$getError('workout_type')"
        v-model="form.workout_type"
        :items="workoutTypes"
        item-text="text"
        item-value="id"
        outlined
        flat
      ></v-select> -->
      <v-select
        v-if="!isLoading"
        label="Link To"
        v-model="linkType"
        :items="linkTypes"
        item-text="text"
        item-value="id"
        :disabled="disabledInputs"
        outlined
        flat
      ></v-select>

      <v-skeleton-loader v-else type="text" />
    </v-col>

    <v-col cols="12" v-if="linkType == 'promo_type_id'">
      <v-select
        v-if="!isLoading"
        label="Internal Link"
        :error-messages="form.$getError('promo_type_id')"
        v-model="form.promo_type_id"
        :items="promoTypes"
        :disabled="disabledInputs"
        item-text="name"
        item-value="id"
        outlined
        flat
      ></v-select>
      <v-skeleton-loader v-else type="text" />
    </v-col>

    <v-col cols="12" v-if="linkType == 'url'">
      <v-text-field
        v-if="!isLoading"
        v-model="form.url"
        label="External URL"
        :disabled="disabledInputs"
        :error-messages="form.$getError('url')"
        outlined
        flat
      />
      <v-skeleton-loader v-else type="text" />
    </v-col>

    <v-col cols="12" mb-2>
      <v-text-field
        v-model="form.video_public_id"
        label="Video Path"
        :error-messages="form.$getError('video_public_id')"
        auto-grow
        outlined
        flat
      />
    </v-col>
  </v-row>
</template>

<script>
import PromoImageUpload from '@/components/elements/uploads/PromoImageUpload'
import Form from '@/utils/form'

import { mapGetters, mapActions, mapState } from 'vuex'
import { cloneDeep } from 'lodash'

const PROMO_TYPE_ID = 'promo_type_id'
const URL = 'url'

export default {
  name: 'PromoDetailsForm',

  components: {
    PromoImageUpload,
  },

  props: {
    saving: Boolean,
    deleting: Boolean,
    data: {
      type: Form,
      required: true,
    },
  },

  data() {
    return {
      form: this.data,
      imageFile: null,
      accessDateMenu: false,
      originalPromoImage: {
        thumb_url: null,
        url: null,
      },
      linkType: null,
      linkTypes: [
        { id: null, text: 'None' },
        { id: PROMO_TYPE_ID, text: 'Internally (within App)' },
        { id: URL, text: 'External URL' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'promos/isLoading',
    }),

    ...mapState({
      promoTypes: (state) => state.promos.promoTypes.list,
    }),

    disabledInputs() {
      return this.saving || this.deleting
    },
  },

  async created() {
    this.copyOriginalImage(this.data.image)
    await this.getPromoTypes({ page: 1 })
  },

  methods: {
    ...mapActions({
      getPromoTypes: 'promos/fetchPromoTypes',
    }),
    copyOriginalImage(image) {
      this.originalPromoImage = cloneDeep(image)
    },

    promoImageChange(image) {
      this.form.image = image
    },
  },

  watch: {
    data(promo) {
      this.form = promo
      this.linkType = this.form.promo_type_id
        ? PROMO_TYPE_ID
        : this.form.url
        ? URL
        : null

      this.copyOriginalImage(promo.image)
    },

    linkType(val) {
      if (val === PROMO_TYPE_ID) this.form.url = null
      else if (val === URL) this.form.promo_type_id = null
      else if (val === null) {
        this.form.url = null
        this.form.promo_type_id = null
      }
    },
  },
}
</script>
